import discordIcon from "../../assets/icons/discord.svg";
import githubIcon from "../../assets/icons/github.svg";
import telegramIcon from "../../assets/icons/telegram.svg";
import twitterIcon from "../../assets/icons/twitter.svg";
import epochStakeAuction from "../../assets/icons/dapphub-menu/epoch-stake-auction.svg";
import liquidityBonds from "../../assets/icons/dapphub-menu/liquiity-bonds.svg";
import oadaBorrow from "../../assets/icons/dapphub-menu/oada-borrow.svg";
import oada from "../../assets/icons/dapphub-menu/oada.svg";

export type AsideLink = {
  title: string;
  icon: string;
  href: string;
  soon?: boolean;
  new?: boolean;
  outsideLink?: boolean;
  parent: string;
};

const asideLinks: AsideLink[] = [
  // {
  //   title: "Dashboard",
  //   icon: dashboard,
  //   href: "dashboard",
  //   parent: "dashboard",
  // },
  {
    title: "Liquidity Bonds",
    icon: liquidityBonds,
    href: "/bonds",
    parent: "/bonds",
  },
  {
    title: "OADA",
    icon: oada,
    href: "/dashboard",
    parent: "/oada",
  },
  {
    title: "Epoch Stake Auction",
    icon: epochStakeAuction,
    href: "/epoch-stake-auction/dashboard",
    parent: "/epoch-stake-auction",
  },
  // {
  //   title: "OSPLASH",
  //   icon: osplash,
  //   href: "osplash/dashboard",
  //   new: true,
  //   parent: "osplash",
  // },
  {
    title: "OADA Borrow",
    icon: oadaBorrow,
    href: "#",//"oada-borrow",
    soon: true,
    parent: "/oada-borrow",
  },
];

const topNavLinks: {
  [key: string]: { title: string; href: string; isExternal?: boolean }[];
} = {
  bonds: [
    {
      title: "Dashboard",
      href: "/bonds/dashboard",
    },
    {
      title: "Bonds",
      href: "/bonds/bonds",
    },
    {
      title: "Your Page",
      href: "/bonds/your-page",
    },
    {
      title: "Learn",
      href: "/bonds/faq",
    },
  ],
  oada: [
    {
      title: "Dashboard",
      href: "/dashboard",
    },
    {
      title: "Mint-Stake-Earn",
      href: "/oada/mint-stake-earn",
    },
    {
      title: "Swap",
      href: "//app.splash.trade/ADA-f6099832f9563e4cf59602b3351c3c5a8a7dda2d44575ef69b82cf8d",
      isExternal: true,
    },
  ],
  osplash: [
    {
      title: "Dashboard",
      href: "/osplash/dashboard",
    },
    {
      title: "Mint-Stake-Earn",
      href: "/osplash/mint-stake-earn",
    },
  ],
  "epoch-stake-auction": [
    {
      title: "Dashboard",
      href: "/epoch-stake-auction/dashboard",
    },
  ],
};

const asideFooterLinks = [
  {
    title: "Home",
    href: "//www.optim.finance",
  },
  {
    title: "Docs",
    href: "//optim-finance.gitbook.io/optim-finance",
  },
  {
    title: "Terms",
    href: "//www.optim.finance/disclaimer",
  },
  {
    title: "Audits",
    href: "//optim-finance.gitbook.io/optim-finance/audits/oada-system-audit",
  },
];

const socialLinks = [
  {
    title: "Telegram",
    href: "//t.me/Optim_Fi",
    icon: telegramIcon,
  },
  {
    title: "Twitter",
    href: "//twitter.com/optimfi",
    icon: twitterIcon,
  },
  {
    title: "Github",
    href: "//github.com/optimfinance",
    icon: githubIcon,
  },
  {
    title: "Discord",
    href: "//discord.gg/VZ329q7x69",
    icon: discordIcon,
  },
];

export { asideFooterLinks, asideLinks, socialLinks, topNavLinks };
